<template>
  <div class="wf__notFound">
    <h1>페이지를 찾을수 없습니다</h1>

    <router-link class="wf__goHome" to="/">Home</router-link>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<style scoped>
.wf__goHome {
  color: var(--wf-primary-color);
  font-size: 1.5rem;
}
.wf__goHome:hover {
  opacity: 0.75;
}
.wf__notFound {
  padding: 5rem;
  min-height: 50vh;
}
</style>
